import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';
import AvatarImage from '@shared/ui/display/AvatarImage';
import { useAppSelector } from '@app/store/Hooks';
import { Body } from '@components/styled/Typography';
const ProfileInfoContainer = styled.div(() => ({
    display: 'flex',
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    cursor: 'pointer',
}));
const AvatarWrapper = styled.div(props => ({
    width: `${props.theme.spacing_sizes.xs * 5}px`,
    height: `${props.theme.spacing_sizes.xs * 5}px`,
    cursor: 'default',
    borderRadius: '50%',
}));
const ProfileInfoDetailsContainer = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: `${props.theme.spacing_sizes.m}px`,
}));
const ProfileInfo = ({ onClick = () => { } }) => {
    var _a;
    const theme = useTheme();
    const { user } = useAppSelector(state => state.user);
    const { currentUserProfile } = useAppSelector(state => state.profile);
    return (_jsxs(ProfileInfoContainer, { onClick: onClick, children: [_jsx(AvatarWrapper, { children: _jsx(AvatarImage, { imageUrl: (_a = currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.profilePicture) === null || _a === void 0 ? void 0 : _a.thumbnail, publicName: currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.displayName }) }), _jsxs(ProfileInfoDetailsContainer, { children: [_jsx(Body, { children: (currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.displayName) || `ID: ${currentUserProfile === null || currentUserProfile === void 0 ? void 0 : currentUserProfile.id}` }), _jsx(Body, { color: theme.palette.text.secondary, children: (user === null || user === void 0 ? void 0 : user.email) || '' })] })] }));
};
export default ProfileInfo;
